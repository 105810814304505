import patientProfileService from "../../../core/services/patientProfileService";
import { 
    UpdateInfoRequest
} from "../../../core/grpc/generated/patientProfile_pb";

const UpdateInfo = async (info) => {
    try {
        const req = new UpdateInfoRequest();
        req.setSocialsecuritynumber(info.social_security_number);
        req.setIdentitynumber(info.identity_number);
        req.setBirthdate(info.birth_date);
        req.setMothername(info.mother_name);
        req.setFathername(info.father_name);

        const res = await patientProfileService.updateInfo(req);

        return res;
        
    } catch (error) {
        console.log(error);
    }
}

export {
    UpdateInfo
}