<template>
    <div class="namefather-form">
        <span class="p-input-icon-right">
            <img 
                class="icon" 
                src="../../../../../assets/icons/accounts/exclamation.svg" 
                alt="" 
                v-tooltip.right="'Campo obrigatório, aceita a palavra Desconhecido'"
            />
            <InputText
                :value="modelValue"
                :class="conditionError.class"
                placeholder="Nome do pai"
                @input="updateModel"
                @keydown.enter.prevent="$emit('submit')"
            />
        </span>

        <div class="errormessages" v-if="conditionError.status">
            <p class="p-invalid">{{ msgErros.fatherName[0] }}</p>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";

export default {
    props: ["modelValue", "msgErros"],
    emits: ["update:modelValue", "callSaveInfo", "submit"],
    setup(props, { emit }) {
        const conditionError = computed(() => {
            if(props.msgErros.fatherName[0] === 'Nome do pai deve ter, no mínimo, 2 nomes com 2 caracteres cada' && WordCount(props.modelValue) < 2){
                return { status: true, class: 'p-invalid' };
            }
            if(props.msgErros.fatherName.length && !props.modelValue){
                return { status: true, class: 'p-invalid' };
            }
            return {status: false, class: ''};
        });

        const WordCount = (str) => {
            return str.split(' ')
                .filter(function(n) { return n != '' })
                .length;
        }

        const updateModel = (e) => {
            const value = e.target.value;
            emit("update:modelValue", value);
        }

        return{
            conditionError,
            updateModel,
            WordCount,
        }
    },
};
</script>


<style lang="scss" scoped>
.namefather-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Roboto", sans-serif;
    
    .messages{
        display: flex;
        flex-direction: column;
        margin: 4px 0;
        color: #2D313D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .4px;
        margin-bottom: 28px;
        margin-top: 8px;
    }
    .errormessages{
        font-size: 12px;
        margin: 4px 0 !important;
    }

    .icon{
        position: absolute;
        width: 16px;
        top: 16px;
        right: 16px;
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1.5px !important;
    text-align: left !important;
    padding-right: 38px;
    &::placeholder{
        color: #BDBDBD;
    }
}
::v-deep(.correction-bordertop) {
    border-top: 1px solid #ced4da !important;
    &:hover{
        border-top: 1px solid #FF6A33 !important;
    }
}
::v-deep(.p-invalid) {
    border-color: #f44336 !important;
}
::v-deep(.p-input-icon-right) {
    width: 100%;
    .pi{
        margin-right: 10px;
    }
}
</style>