<template>
    <div class="birthday-form">
        <Calendar 
            inputId="disableddays" 
            v-model="date" 
            :class="correctionBorderBottom"
            placeholder="Data de nascimento"
            dateFormat="dd/mm/yy"
            :maxDate="today"
            :manualInput="false"
            @date-select="updateModel"
        />
        <!-- <Calendar 
            inputId="disableddays" 
            v-model="date" 
            placeholder="Data de nascimento"
            dateFormat="dd / mm / yy"
            :disabledDates="invalidDates" 
            :disabledDays="[0,6]" 
            :manualInput="false" 
        /> -->

        
        <div class="errormessages" v-if="conditionError">
            <p class="p-invalid">{{ msgErros.birthDate[0] }}</p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";

export default {
    props: ["modelValue", "msgErros"],
    emits: ["update:modelValue", "callSaveInfo"],
    setup(props, { emit }) {
        let today = new Date();
        const date = ref("");
       
        const correctionBorderBottom = computed(() => {
            if(props.msgErros.birthDate.length && !date.value){
                return 'p-invalid'
            }
            return ''
        })

        const conditionError = computed(() => {
            if(props.msgErros.birthDate.length && !date.value){
                return true;
            }
            
            return false
        })

        const updateModel = (e) => {
            const day = e.getUTCDate().toString().padStart(2, 0)
            const month = (e.getMonth() + 1).toString().padStart(2, 0);
            const fullYear = e.getUTCFullYear();
            const fullDate = fullYear + '-' + month + '-' + day;

            console.log(day)
            
            emit("update:modelValue", fullDate);
        }

        return{
            date,
            updateModel,
            today,
            correctionBorderBottom,
            conditionError,
        }
    },
};
</script>


<style lang="scss" scoped>
.birthday-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Roboto", sans-serif;
    
    .messages{
        display: flex;
        flex-direction: column;
        margin: 4px 0;
        color: #2D313D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .4px;
        margin-bottom: 28px;
        margin-top: 8px;
    }
    .errormessages{
        font-size: 12px;
        margin: 4px 0 !important;
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1.5px !important;
    text-align: left !important;
    &::placeholder{
        color: #BDBDBD;
    }
}
::v-deep(.p-invalid) {
    border-color: #f44336 !important;
}

::v-deep(.p-input-icon-right) {
    width: 100%;
    .pi{
        margin-right: 10px;
    }
}
</style>