<template>
    <div class="cpf-form mt-24">
        <span class="p-input-icon-right">
            <img 
                class="icon" 
                src="../../../../../assets/icons/accounts/exclamation.svg" 
                alt="" 
                v-tooltip.right="'O CPF deve conter 11 dígitos'"
            />
            <InputMask
                v-model="cpf"
                :class="conditionError.class"
                mask="999.999.999-99"
                placeholder="CPF"
                @change="updateModel"
                @keydown.enter.prevent="$emit('submit')"
                autofocus
            />
        </span>        

        <div class="errormessages" v-if="conditionError.status">
            <p class="p-invalid">{{msgErros.socialSecurityNumber[0]}}</p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";

export default {
    props: ["modelValue", "msgErros", "cpf_used"],
    emits: ["update:modelValue", "callSaveInfo", "submit"],
    setup(props, { emit }) {
        const cpf = ref("");
        
        const conditionError = computed(() => {
            if(props.msgErros.socialSecurityNumber[0] === 'CPF já está em uso.' && props.modelValue === props.cpf_used ){
                return { status: true, class: 'p-invalid' };
            }
            if(props.msgErros.socialSecurityNumber[0] === 'CPF inválido.' && props.modelValue === props.cpf_used){
                return { status: true, class: 'p-invalid' };
            }
            if(props.msgErros.socialSecurityNumber.length && !props.modelValue){
                return { status: true, class: 'p-invalid' };
            }
            return { status: false, class: '' };
        });

        const updateModel = (e) => {
            const number = e.target.value;
            emit("update:modelValue", number.replace(/[^0-9]/g, ""));
        }

        return{
            cpf,
            updateModel,
            conditionError,
        }
    },
};
</script>


<style lang="scss" scoped>
.cpf-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Roboto", sans-serif;
    
    .messages{
        display: flex;
        flex-direction: column;
        margin: 4px 0;
        color: #2D313D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .4px;
        margin-bottom: 28px;
        margin-top: 8px;
    }
    .errormessages{
        font-size: 12px;
        margin: 4px 0 !important;
    }
    .icon{
        position: absolute;
        width: 16px;
        top: 16px;
        right: 16px;
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1.5px !important;
    text-align: left !important;
    padding-right: 38px;
    &::placeholder{
        color: #BDBDBD;
    }
}
::v-deep(.p-invalid) {
    border-color: #f44336 !important;
}
::v-deep(.p-input-icon-right) {
    width: 100%;
    .pi{
        margin-right: 10px;
    }
}
</style>